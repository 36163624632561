import { Injectable } from '@angular/core';
import { LanguageSetup } from './language-setup';
import { HttpRequestor } from './http-requestor';
import { LocationTracker } from './location-tracker';
import { UserStatusPolling } from './user-status/user-status-polling';
import { Const } from './const';
import { Events } from './events';

@Injectable({ providedIn: 'root' })
export class UserStatusManager {

  private strategy: UserStatusPolling;
  private canEmit: boolean;

  constructor(public events: Events,
    public langSetup: LanguageSetup,
    public httpReq: HttpRequestor,
    public locationTracker: LocationTracker
  ) {
    this.strategy = new UserStatusPolling(this.langSetup, this.httpReq, this.locationTracker);
    this.canEmit = false;
  }

  private userStatus: any = null;
  getUserStatus(): any {
    return this.userStatus;
  }

  /** 初期化処理はGPSのトラッキングの開始後に行うこと。 */
  initialize(userId: string) {
    this.canEmit = true;
    this.strategy.initialize(this.statusReceiveCallback, userId);

    this.strategy.execute();
  }

  /** resDataはユーザステータス取得APIの確認時のレスポンスの形式で返却すること。 */
  statusReceiveCallback = (resData: any) => {
    const status = this.userStatus;
    this.userStatus = resData;
    // 予約をしていないユーザの場合、ユーザステートを1に設定 (FY22事前予約対応)
    // APIが変更され、予約がない場合はユーザステート情報がいない為
    if( resData.userStatus == null ) resData.userStatus = Const.USER_STATE_ACCEPTING_DISPATCH_ORDER;
    // 利用するAPI変更による想定外のエラーを防ぐための処理
    if(resData.userStatus) resData.user_status = resData.userStatus;
    if (this.canEmit) { //ストラテジクラス内の非同期処理も考慮し、本クラス内でfinalize状態であれば発火をブロックする。
      this.events.publish(String(resData.user_status), resData);
      this.events.publish(Const.USER_STATE_ANY, resData);
      if (status.userStatus !== resData.userStatus){
        this.updateStatusTexts();
      }
    }
  }

  finalize() {
    this.canEmit = false;
    this.strategy.finalize();
  }

  debugUserStatus ="";

  private updateStatusTexts() {
    let status = this.userStatus;
    let debugTextArea = document.getElementById("debugText") as HTMLTextAreaElement;
    let outString = debugTextArea.value;
     if (status.userStatus != this.debugUserStatus) {
      const nowDateTime = new Date();
      const options = { year: "numeric", month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' } as Intl.DateTimeFormatOptions;
      const formattedDate = new Intl.DateTimeFormat('ja-JP', options).format(nowDateTime);
      const timeAndStatus = formattedDate + " status:" + status.userStatus;
      // 文字列配列を1文字列に結合
      outString += "\n" + timeAndStatus;
      this.debugUserStatus = status.userStatus;
      let strArray = outString.split("\n").filter((str)=>str !=="");
      if (strArray.length > 10) {
        //10個の要素がある場合は新しい9個の要素を残す
        strArray = strArray.slice(strArray.length - 10);
      }
      debugTextArea.value = strArray.join("\n");
    }
  }
}
